import "../styles/global.css";
import GlHeader from "./glHeader";
import GlFooter from "./glFooter";
import ToTop from "./toTop";

function Layout({ children }, props) {
  return (
    <>
      <div className="columns container">
        <div className="col">
          <GlHeader />
        </div>
        <div className="col">
          {children}
        </div>
      </div>
      <ToTop />
      {/*
      <div>
        {children}
      </div>
      */}
      
      <GlFooter />
    </>
  );
}
export default Layout;
