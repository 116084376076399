import { useEffect, useState } from "react";

export default function ToTop() {
  const [active, setActive] = useState(false);

  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  var checkOffset = debounce(function () {
    if (window.pageYOffset >= 250) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, 250);

  useEffect(() => {
    window.addEventListener("resize", checkOffset);
    window.addEventListener("scroll", checkOffset);
    window.addEventListener("orientationchange", checkOffset);
  }, []);

  return (
    <div
      id="to-top"
      className={active ? "shadow active" : "shadow"}
      aria-label="to top"
      onClick={() => window.scrollTo(0, 0)}
    >
      <span>↑</span>
    </div>
  );
}
