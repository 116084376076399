import { useEffect, useState } from "react";
import { Link } from "gatsby";

function GlHeader(props) {
  const [navActive, setNavActive] = useState(false);
  const [expanded, setExpanded] = useState();
  const [expandedSlow, setExpandedSlow] = useState();
  const [contracted, setContracted] = useState();
  const [container, setContainer] = useState();

  // Get the natural height of an element
  function getHeight(elem) {
    elem.style.display = "block"; // Make it visible
    var height = elem.scrollHeight + "px"; // Get its height
    elem.style.display = ""; //  Hide it again
    return height;
  }

  // Show an element
  function show(elem) {
    var height = getHeight(elem); // Get natural height
    elem.classList.add("active"); // Make element visible
    elem.style.height = height; // Update max-height
    window.setTimeout(function () {
      elem.style.height = "";
    }, 350);
  }

  // Hide an element
  function hide(elem) {
    // Give the element a height to change from
    elem.style.height = elem.scrollHeight + "px";
    // Set the height back to 0
    window.setTimeout(() => {
      elem.style.height = "0";
    }, 1);
    // When the transition is complete, hide it
    window.setTimeout(() => {
      elem.classList.remove("active");
    }, 100);
  }

  function toggleNav() {
    if (navActive) {
      setNavActive(false);
      hide(document.getElementById("expanded"));
      hide(document.getElementById("expanded-slow"));
      show(document.getElementById("contracted"));
      document
        .getElementById("gl-header-container")
        .classList.remove("show-border");
    } else {
      setNavActive(true);
      show(document.getElementById("expanded"));
      show(document.getElementById("expanded-slow"));
      hide(document.getElementById("contracted"));
      document
        .getElementById("gl-header-container")
        .classList.add("show-border");
    }
  }
  function getElements() {
    setExpanded(document.getElementById("expanded"));
    setExpandedSlow(document.getElementById("expanded-slow"));
    setContracted(document.getElementById("contracted"));
    setContainer(document.getElementById("gl-header-container"));
  }

  /*
  useEffect(() => {
    //if (!expanded)
      //getElements();
    if (props.location.pathname !== '/' && navActive)
      toggleNav();
  }, [props.location.pathname]);
*/
  return (
    <header id="gl-header">
      <div id="gl-header-container" className="">
        <div id="contracted" className="toggle-content active">
          <div className="flex-row" style={{ alignItems: "center" }}>
            <p>Nathan Pasko</p>
          </div>
          <div className="flex-row">
            <button className="toggler" onClick={toggleNav}>
              <div className="toggler-inner"></div>
            </button>
          </div>
        </div>
        <div id="expanded" className="toggle-content">
          <h1 className="toggler" onClick={toggleNav}>
            Nathan Pasko Facts
          </h1>
        </div>
        <div id="expanded-slow" className="toggle-content">
          <hr />
          <p>1 serving per container</p>
          <div className="flex-row">
            <p>
              <strong>Serving size</strong>
            </p>
            <p>
              <strong>5ft 8in</strong>
            </p>
          </div>
          <hr className="thicker" />
          <p className="small-p">
            <strong>Amount per serving</strong>
          </p>
          <div className="flex-row baseline">
            <h2>Calories</h2>
            <h1 className="absolute-bottom-right">421</h1>
          </div>
          <hr className="thick" />
          <div className="flex-row">
            <span></span>
            <p className="small-p">
              <strong>% Daily Value*</strong>
            </p>
          </div>
          <hr />
          <div className="flex-row">
            <p>
              <strong>Designer/Developer</strong>
            </p>
            <p>
              <strong>34%</strong>
            </p>
          </div>
          <hr />
          <div className="flex-row">
            <p>
              <span className="indent"></span>
              <a href="http://avknights.com" target="blank">
                A.V. Knights
              </a>
            </p>
            <p>
              <strong>5%</strong>
            </p>
          </div>
          <hr />
          <div className="flex-row">
            <p>
              <strong>Web</strong>
            </p>
            <p>
              <strong>24%</strong>
            </p>
          </div>
          <hr />
          <div className="flex-row">
            <p>
              <span className="indent"></span>
              <a href="https://tunnelengine.netlify.app" target="blank">
                Tunnel engine
              </a>
            </p>
            <p>
              <strong>8%</strong>
            </p>
          </div>
          <hr />
          <div className="flex-row">
            <p>
              <strong>Games/Toys</strong>
            </p>
            <p>
              <strong>17%</strong>
            </p>
          </div>
          <hr />
          <div className="flex-row">
            <p>
              <span className="indent"></span>
              <Link to="/projects/g-pet/">G. Pet</Link>
            </p>
            <p>
              <strong>3%</strong>
            </p>
          </div>
          <hr />
          <div className="flex-row">
            <p>
              <span className="indent"></span>
              <Link to="/projects/petfinderx/">PetFinderX</Link>
            </p>
            <p>
              <strong>3%</strong>
            </p>
          </div>
          <hr />
          {/*
          <div className="flex-row">
            <p>
              <span className="indent"></span>
              <Link to="/projects/anemone/">Anemone</Link>
            </p>
            <p>
              <strong>3%</strong>
            </p>
          </div>
          <hr />
          */}
          <div className="flex-row">
            <p>
              <span className="indent"></span>
              <Link to="/projects/cavalcade/">Cavalcade</Link>
            </p>
            <p>
              <strong>1%</strong>
            </p>
          </div>
          <hr />
          <div className="flex-row">
            <p>
              <span className="indent"></span>
              <Link to="/projects/soundtracks/">Soundtracks</Link>
            </p>
            <p>
              <strong>3%</strong>
            </p>
          </div>
          <hr />
          <div className="flex-row">
            <p>
              <span className="indent"></span>
              <Link to="/projects/all-games/">All games</Link>
            </p>
            <p>
              <strong>17%</strong>
            </p>
          </div>
          {/*
          <hr />
          <div className="flex-row">
            <p>
              <strong>Blog</strong>
            </p>
            <p>
              <strong>6%</strong>
            </p>
          </div>
          <hr />
          <div className="flex-row">
            <p>
              <span className="indent"></span>
              <Link to="/blog/tags/getting-more-responsive/">
                Getting More Responsive
              </Link>
            </p>
            <p>
              <strong>1%</strong>
            </p>
          </div>
          <hr />
          <div className="flex-row">
            <p>
              <span className="indent"></span>
              <Link to="/blog/tags/dont-be-afraid-of-javascript/">
                Don't Be Afraid of JS
              </Link>
            </p>
            <p>
              <strong>4%</strong>
            </p>
          </div>
          <hr />
          <div className="flex-row">
            <p>
              <span className="indent"></span>
              <Link to="/blog">All tags</Link>
            </p>
            <p>
              <strong>6%</strong>
            </p>
          </div>
          */}
          <hr className="thicker" />
          {/*
          <div className="flex-row">
            <p>
              <Link to="mailto:nathanpasko@gmail.com">
                nathanpasko@gmail.com
              </Link>
            </p>
            <p>21%</p>
          </div>
          <hr className="thick" />
          */}
          <div className="flex-row">
            <p className="readable-line-height">*&emsp;</p>
            <p className="small-p readable-line-height">
              Nathan Pasko is a designer, developer &
              musician. The % Daily Value tells you how much an aspect of Nathan
              contributes to a daily diet. 2,000 calories in a day is used for
              general nutrition advice.
            </p>
          </div>
        </div>
      </div>
    </header>
  );
}

export default GlHeader;
